export const PAGES = {
  INDEX: "/",
  NOT_FOUND: "/404",
  LEGAL: {
    TERMS: "/terms",
    PRIVACY: "/privacy",
  },
  AUTH: {
    SIGN_IN: "/auth/signin",
    SIGN_OUT: "/auth/signout",
    SIGN_UP: "/auth/signup",
    FORGOT_PASSWORD: "/auth/forgot-password",
    RESET_PASSWORD: "/auth/reset-password",
  },
  SETTINGS: "/settings",
  ORGANIZATIONS: "/organizations",
  APP: {
    INDEX: (organizationSlug: string) => `/app/${organizationSlug}`,
    BRAND_SETTINGS: (organizationSlug: string, brandName: string) =>
      `/app/${organizationSlug}/${brandName}/settings`,
    DEFAULT_TARGET: (organizationSlug: string, brandName: string) =>
      `/app/${organizationSlug}/${brandName}/opportunities`,
    OPPORTUNITIES: {
      INDEX: (organizationSlug: string, brandName: string) =>
        `/app/${organizationSlug}/${brandName}/opportunities`,
      OPPORTUNITY: (organizationSlug: string, brandName: string, id: string) =>
        `/app/${organizationSlug}/${brandName}/opportunities/${id}`,
    },
    PROMOTIONS: {
      INDEX: (organizationSlug: string, brandName: string) =>
        `/app/${organizationSlug}/${brandName}/promotions`,
      PROMOTION: (organizationSlug: string, brandName: string, promotionId: string) =>
        `/app/${organizationSlug}/${brandName}/promotions/${promotionId}`,
    },
    DEMOS: {
      INDEX: (organizationSlug: string, brandName: string) =>
        `/app/${organizationSlug}/${brandName}/demos`,
    },
    DISTRIBUTORS: {
      INDEX: (organizationSlug: string, brandName: string) =>
        `/app/${organizationSlug}/${brandName}/distributors`,
    },
    STORES: {
      INDEX: (organizationSlug: string, brandName: string) =>
        `/app/${organizationSlug}/${brandName}/stores`,
      STORE: {
        INDEX: (organizationSlug: string, brandName: string, storeId: string) =>
          `/app/${organizationSlug}/${brandName}/stores/${storeId}`,
      },
    },
    THREADS: {
      INDEX: (organizationSlug: string, brandName: string) =>
        `/app/${organizationSlug}/${brandName}/threads`,
    },
    LEADS: {
      INDEX: (organizationSlug: string, brandName: string) =>
        `/app/${organizationSlug}/${brandName}/leads`,
      LEAD_GENERATION: (organizationSlug: string, brandName: string) =>
        `/app/${organizationSlug}/${brandName}/leads/generate`,
    },
  },
  API: {
    GMAIL: {
      LOGIN_CALLBACK: "/api/gmail/login-callback",
    },
    OUTLOOK: {
      LOGIN_CALLBACK: "/api/outlook/login-callback",
    },
  },
} as const;
