/* eslint-disable no-console */
"use client";

import { useSession } from "next-auth/react";
import posthog from "posthog-js";
import { PostHogProvider as PostHogProviderInternal } from "posthog-js/react";
import { useEffect } from "react";
import { getCookiePreferences } from "@/components/CookieConsent/CookieConsent";

if (typeof window !== "undefined" && process.env.NEXT_PUBLIC_POSTHOG_KEY) {
  const preferences = getCookiePreferences();
  if (preferences && preferences.analytics) {
    console.debug("Initializing PostHog");
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
      api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://us.i.posthog.com",
      person_profiles: "always",
      loaded: (posthog) => {
        if (process.env.NODE_ENV === "development") posthog.debug();
      },
    });
  } else {
    console.debug("Not initializing PostHog");
  }
}

export default function PostHogProvider({ children }: { children: React.ReactNode }) {
  const preferences = getCookiePreferences();
  const session = useSession();

  useEffect(() => {
    if (session.data?.user && preferences?.analytics) {
      posthog.identify(session.data.user.id, {
        email: session.data.user.email,
      });
    }
  }, [session.data?.user, preferences?.analytics]);

  if (!preferences?.analytics) {
    console.debug("Not creating PostHog client");
    return <>{children}</>;
  }

  console.debug("Creating PostHog client");
  return <PostHogProviderInternal client={posthog}>{children}</PostHogProviderInternal>;
}
