import { PAGES } from "@/utils/pages";
import Link from "next/link";

export default function Footer() {
  const legalLinks = [
    { name: "Terms", href: PAGES.LEGAL.TERMS },
    { name: "Privacy", href: PAGES.LEGAL.PRIVACY },
  ];

  return (
    <footer className="flex flex-row justify-between w-full px-8 py-4 text-sm">
      <div>Treater, Inc.</div>
      <div className="flex flex-row justify-end gap-x-4">
        {legalLinks.map((item) => (
          <Link key={item.name} href={item.href} className="text-sm" target="_blank">
            {item.name}
          </Link>
        ))}
      </div>
    </footer>
  );
}
