"use client";

import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import Link from "next/link";
import posthog from "posthog-js";

export type CookiePreferences = {
  analytics: boolean;
};

const COOKIE_PREFERENCES_KEY = "cookie-preferences";

export function getCookiePreferences(): CookiePreferences | null {
  if (typeof window === "undefined") return null;
  const stored = localStorage.getItem(COOKIE_PREFERENCES_KEY);
  return stored ? JSON.parse(stored) : null;
}

export function setCookiePreferences(preferences: CookiePreferences) {
  if (typeof window === "undefined") return;
  localStorage.setItem(COOKIE_PREFERENCES_KEY, JSON.stringify(preferences));

  // Handle PostHog opt-out
  if (!preferences.analytics) {
    posthog.opt_out_capturing();
  } else {
    posthog.opt_in_capturing();
  }

  // Handle Fullstory opt-out
  if (typeof window !== "undefined") {
    // @ts-expect-error FS is added by the FullStory script
    const fs = window.FS;
    if (fs) {
      if (!preferences.analytics) {
        fs.shutdown();
      } else {
        fs.restart();
      }
    }
  }
}

export default function CookieConsent() {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const preferences = getCookiePreferences();
    if (!preferences) {
      setShowBanner(true);
    }
  }, []);

  if (!showBanner) return null;

  const acceptAll = () => {
    setCookiePreferences({ analytics: true });
    setShowBanner(false);
  };

  const rejectAll = () => {
    setCookiePreferences({ analytics: false });
    setShowBanner(false);
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-4 shadow-lg z-50">
      <div className="max-w-7xl mx-auto flex flex-col sm:flex-row items-center justify-between gap-4">
        <div className="text-sm text-gray-600">
          We use cookies to improve your experience. We never sell your data. See our{" "}
          <Link href="/privacy" className="underline">
            Privacy Policy
          </Link>{" "}
          for more details.
        </div>
        <div className="flex gap-2">
          <Button variant="outline" onClick={rejectAll}>
            Reject non-essential
          </Button>
          <Button onClick={acceptAll} className="bg-main-500 text-white hover:bg-main-600">
            Accept All
          </Button>
        </div>
      </div>
    </div>
  );
}
